'use client'
import { Dialog, DialogContent } from '@mui/material'
import { DummyRegisterHeader, Footer, StepRegisterForm } from '@sugar/landingpage-components'

import { useEffect, useRef, useState } from 'react'
import { Container } from '../components/Container'



export default function Index() {
  const [showReg, setShowReg] = useState(false)
  const firedOnce = useRef(false)
  const lastScroll = useRef(0)

  useEffect(() => {
    const windowRef = window as any
    const _mtm = windowRef._mtm = windowRef._mtm || [];
    _mtm.push({'mtm.startTime': (new Date().getTime()), 'event': 'mtm.Start'});

    const script = document.createElement("script");
    script.src = "https://ww1.getasugar.de/js/container_nrZy0wOw.js";
    script.async = true;

    document.body.appendChild(script);

    const params = new URLSearchParams(location.search)
    if(params.has('utm_source') || params.has('utm_medium') || params.has('utm_campaign')) {
      const source = {
        source: params.get('utm_source'),
        medium: params.get('utm_medium'),
        campaign: params.get('utm_campaign'),
        site: params.get('gas_site'),
        placement: params.get('gas_place')
      }
      if(!localStorage.getItem('initial_source')) {
        localStorage.setItem('initial_source', JSON.stringify(source))
      }
      localStorage.setItem('current_source', JSON.stringify(source))
    }

    if(params.has('sst') && params.has('sstid')) {
      localStorage.setItem('current_sst', JSON.stringify({
        sst: params.get('sst'),
        sstid: params.get('sstid')
      }))
    }

    const func = () => {
      if(window.innerHeight + window.scrollY >= document.documentElement.scrollHeight - 20 && window.scrollY >= lastScroll.current) {
        setShowReg(true)
      }
      lastScroll.current = window.scrollY
    }
    window.addEventListener('scroll', func)

    return () => {
      window.removeEventListener('scroll', func)
    }
  }, [])

  useEffect(() => {
    let timeout: any
    const func = () => {
      clearTimeout(timeout)
      if(!firedOnce.current) {
        timeout = setTimeout(() => {
          firedOnce.current = true
          setShowReg(true)
        }, 5000)
      }
    }
    window.addEventListener('mousemove',func)
    window.addEventListener('scroll',func)
    timeout = setTimeout(() => {
      firedOnce.current = true
      setShowReg(true)
    }, 5000)

    return () => {
      window.removeEventListener('mousemove', func)
      window.removeEventListener('scroll', func)
    }
  }, [])

  return (
    <>
      <DummyRegisterHeader onClick={() => {
            setShowReg(true)
            // open register form
          }} />
      {showReg && (
        <Dialog 
          maxWidth={'xs'} 
          fullWidth 
          open={showReg} 

          onClose={(event, reason) => {
            if(reason !== 'backdropClick') {
              setShowReg(false)
            }
          }}
          sx={{
            '.MuiDialog-paper': {
              minHeight: '320px'
            }
          }}
        >
          <DialogContent>
            <StepRegisterForm close={() => setShowReg(false)} />
          </DialogContent>
        </Dialog>
      )}
      <Container onClick={() => {
            setShowReg(true)
            // open register form
          }} />
      <Footer withSignup={false} showRegistrationHint={true} baseUrl="https://luxus.getasugar.de" />
    </>
  );
}
