'use client';
import { Box, Grid, Theme } from '@mui/material';
import { DummyUserCard } from '@sugar/landingpage-components';
import { BoundingContainer } from 'libs/landingpage-components/src/lib/styles/common';

const tiles = [
  {
    picture: '01_SDgesucht_24_Frankfurt-am-Main.jpg',
    age: 24,
    name: 'SDgesucht',
    location: 'Frankfurt am Main',
    isOnline: true,
  },
  {
    picture: '02_Valerie_22_Muenchen.jpg',
    age: 22,
    name: 'Valerie',
    location: 'München',
  },
  {
    picture: '03_Neugier_25_Hamburg.jpg',
    age: 25,
    name: 'Neugier',
    location: 'Hamburg',
    isOnline: true,
  },
  {
    picture: '04_Dirtymind_28_Potsdam.jpg',
    age: 28,
    name: 'Dirtymind',
    location: 'Potsdam',
  },
  {
    picture: '05_Dorfmaedchen_21_Augsburg.jpg',
    age: 21,
    name: 'Dorfmädchen',
    location: 'Augsburg',
  },
  {
    picture: '06_Saskia_27_Koeln.jpg',
    age: 27,
    name: 'Saskia',
    location: 'Köln',
    isOnline: true,
  },
  {
    picture: '07_Kim_24_Wien.jpg',
    age: 24,
    name: 'Kim',
    location: 'Wien',
    isOnline: true,
  },
  {
    picture: '08_Marie_39_Magdeburg.jpg',
    age: 39,
    name: 'Marie',
    location: 'Magdeburg',
  },
  {
    picture: '09_Spoil-me_34_Berlin.jpg',
    age: 34,
    name: 'Spoil-me',
    location: 'Berlin',
  },
  {
    picture: '10_Coco_19_Bremen.jpg',
    age: 19,
    name: 'Coco',
    location: 'Bremen',
  },
  {
    picture: '11_Trudi_26_Aachen.jpg',
    age: 26,
    name: 'Trudi',
    location: 'Aachen',
  },
  {
    picture: '12_Kathi_29_Basel.jpg',
    age: 29,
    name: 'Kathi',
    location: 'Basel',
  },
  {
    picture: '13_Rebecca_27_Stuttgart.jpg',
    age: 27,
    name: 'Rebecca',
    location: 'Stuttgart',
  },
  {
    picture: '14_Wanderlust_28_Dresden.jpg',
    age: 28,
    name: 'Wanderlust',
    location: 'Dresden',
  },
  {
    picture: '15_Lotusblume_23_Salzburg.jpg',
    age: 23,
    name: 'Lotusblume',
    location: 'Salzburg',
  },
  {
    picture: '16_Angie_22_Muenster.jpg',
    age: 22,
    name: 'Angie',
    location: 'Münster',
  },
];

export const Container = (props: any) => {
  const { onClick } = props;

  return (
    <BoundingContainer
      sx={{ marginTop: '80px', marginBottom: '1em' }}
    >
      <Grid container spacing={2}>
        {tiles.map((el, idx) => (
          <Grid item xs={12} md={3} key={idx}>
            <DummyUserCard item={el} onClick={onClick} />
          </Grid>
        ))}
      </Grid>
    </BoundingContainer>
  );
};
